import react from 'react';



const IframeLink = ({ url }) => {

    return (
        <>
            <iframe style={{ width: '100%', height: '100vh' }} src={url}></iframe>
        </>
    )
}


export default IframeLink;